<!--
 * @Description: 公共需求池-详情
 * @Author: zhang zhen
 * @Date: 2023-02-15 15:51:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-07 16:37:04
 * @FilePath: /page-sass/src/views/requirement/requirementPool/modules/requirementPoolDetails.vue
-->
<template>
  <div class="ForUserAndPurchaseDetails">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col>
        <div class="ForUserAndPurchaseDetails-title">{{ purchaseTitle }}</div>
        <div class="ForUserAndPurchaseDetails-subtitle">
          <span class="label">需求编号：{{ purchaseNo }}</span>
          <a class="copy" @click="handleCopy">复制</a>
        </div>
      </a-col>
      <a-col>
        <a-button type="primary" @click="handleQuote">参与需求</a-button>
      </a-col>
    </a-row>
    <basicInfo :purchaseId="purchaseId" :formType="formType" :infoStatus="status" :needLoadNow="true" :needSteps="false" :isOut="true" />
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import basicInfo from '@/views/requirement/requirementList/modules/basicInfo.vue'
export default {
  name: 'requirementPoolDetails',
  components: {
    basicInfo
  },
  data() {
    return {
      purchaseTitle: '',
      purchaseNo: '',
      purchaseId: '',
      showMore: false,
      baseInfo: {},
      purchaseItem: [],
      submitLoading: false,
      formType: 'product',
      status: '1'
    }
  },
  created() {
    // 提取关键参数
    const { purchaseId, purchaseTitle, purchaseNo, formType, status } = this.$route.query
    purchaseId && (this.purchaseId = purchaseId)
    purchaseTitle && (this.purchaseTitle = purchaseTitle)
    purchaseNo && (this.purchaseNo = purchaseNo)
    formType && (this.formType = formType)
    this.status = status || 0
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleDownLoadFile(url) {
      window.open(url)
    },
    /* 复制单号 */
    handleCopy() {
      const _input = document.createElement("input"); // 直接构建input
      _input.value = this.purchaseNo; // 设置内容
      document.body.appendChild(_input); // 添加临时实例
      _input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(_input); // 删除临时实例
      this.$message.success('复制成功')
    },
    /* 参与报价 */
    handleQuote() {
      this.submitLoading = true;
      postAction('/quote', {
        businessId: this.setUserInfo().businessId,
        purchaseId: this.purchaseId
      }).then(res => {
        const { success, message } = res;
        this.submitLoading = false
        if (success) {
          this.$message.success(message)
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.ForUserAndPurchaseDetails {
  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 15px;
    .copy {
      font-size: 13px;
      margin-left: 8px;
      font-weight: 500;
    }
  }
}
</style>
